interface ErrorContext {
  [key: string]: string;
}

const logError = (error: Error, context: ErrorContext = {}): void => {
  const errorDetails = {
    message: error.message,
    stack: error.stack,
    context,
  };

  console.log("Logged Error:--------", errorDetails);

  // Integrate with a third-party service
};

export default logError;
