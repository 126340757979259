"use client";

import Image from "next/image";
import Link from "next/link";

import withBrand from "@/components/with-brand";
import { MAIN_PAGE_URL } from "@/constants";

import logos from "./logo-config";
import Classes from "./logo.module.css";
import Styles from "../../../app/home-page.module.css";

interface LogoProps {
  color: string;
  heightClass?: string;
  size?: string;
  className?: string;
  showondesktop?: string;
  brand?: string;
}

function Logo({
  color,
  heightClass,
  size = "m",
  className,
  showondesktop,
  brand = "staysure",
}: Readonly<LogoProps>): JSX.Element {
  function getLogoImage(lBrand: string, lColor: string, lSize: string): string {
    let logoImage = "";
    logos.forEach((logo) => {
      if (
        logo.brand === lBrand &&
        logo.color === lColor &&
        logo.size === lSize
      ) {
        logoImage = logo.logoImage;
      }
    });
    return logoImage;
  }

  return (
    <div
      className={` ${Styles.logo_wrapper} logo-wrapper md:pl-[35px] pl-spacing-m lg:pt-[20px] md:pt-[16px] pt-[12px] pb-[14px] ${showondesktop === "true" ? "h-[80px]" : "h-[64px]"} ${className} ${heightClass}`}
    >
      <Link
        id="logo-automate-test-id"
        href={MAIN_PAGE_URL}
        role="button"
        tabIndex={0}
        className={`inline-block ${Classes.logo}`}
        target="_blank"
        data-testid="logoLink-test-id"
      >
        <Image
          src={getLogoImage(brand, color, size)}
          alt={`${brand}-logo`}
          className="md:max-w-[158px] sm:max-w-[115px] max-w-[80px] w-full"
          width={127}
        />
      </Link>
    </div>
  );
}

export default withBrand(Logo);
