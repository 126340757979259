import { useBrand } from "@/providers/brand-provider";

const withBrand = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return function WithBrandComponent(props: P) {
    const { brandName } = useBrand();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} brand={brandName} />;
  };
};

export default withBrand;
