import AvantiLogoPrimaryXL from "../../../public/images/logos/avanti-logo-primary-xl.svg";
// import AvantiLogoWhite from "../../../public/images/logos/avanti-logo-white.svg";
import AvantiLogoWhiteXL from "../../../public/images/logos/avanti-logo-white-xl.svg";
import StaysureLogoPrimaryXL from "../../../public/images/logos/staysure-logo-primary-xl.svg";
import StaysureLogoPrimary from "../../../public/images/logos/staysure-logo-primary.svg";
import StaysureLogoWhite from "../../../public/images/logos/staysure-logo-white.svg";

const logos = [
  {
    brand: "staysure",
    color: "primary",
    size: "xl",
    logoImage: StaysureLogoPrimaryXL,
  },
  {
    brand: "staysure",
    color: "primary",
    size: "m",
    logoImage: StaysureLogoPrimary,
  },
  {
    brand: "staysure",
    color: "white",
    size: "xl",
    logoImage: StaysureLogoWhite,
  },
  {
    brand: "avanti",
    color: "primary",
    size: "xl",
    logoImage: AvantiLogoPrimaryXL,
  },
  {
    brand: "avanti",
    color: "primary",
    size: "m",
    logoImage: AvantiLogoPrimaryXL,
  },
  {
    brand: "avanti",
    color: "white",
    size: "xl",
    logoImage: AvantiLogoWhiteXL,
  },
];

export default logos;
