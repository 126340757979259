export const MAGNOLIA_BASE_URL = process.env.NEXT_PUBLIC_MGNL_HOST || "";
export const MAGNOLIA_ANNOTATION_URL = `${MAGNOLIA_BASE_URL}/.rest/template-annotations/v1`;
export const MAGNOLIA_PAGES_URL = `${MAGNOLIA_BASE_URL}/.rest/pages/v1`;
export const DYNAMIC_FOOTER_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/dynamicFooters`;
export const NOT_FOUND_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/notFounds`;
export const CONTENT_PATH: string =
  process.env.NEXT_PUBLIC_MGNL_SITE_PATH ?? "";
export const language: string = "en";
export const HELPOVERLAY_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/helpoverlays`;
export const MAIN_PAGE_URL = `https://www.staysure.co.uk/`;
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENV || "production";
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const ADDRESS_API_URL = process.env.NEXT_PUBLIC_ADDRESS_API_URL;
export const LOGIN_PAGE_URL = `https://my.staysure.co.uk/signin`;
export const PAYMENT_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/payments`;
export const EXPAT_PAGE_URL = `http://www.staysure.com/`;
export const QA_ENVIRONMENT = `https://quotebff.api.sae.qa.staysuregroup.com/`;

export const ADDRESS_API_KEY = process.env.NEXT_PUBLIC_ADDRESS_API_KEY;
export const BFF_API_KEY = process.env.NEXT_PUBLIC_BFF_API_KEY;
export const OPTIMIZELY_ID = process.env.NEXT_PUBLIC_OPTIMIZELY_ID || "";

export const SINGLE_TRIP = "SINGLE_TRIP";
export const ANNUAL_MULTI_TRIP = "ANNUAL_MULTI_TRIP";

export const BASIC = "BASIC";
export const COMPREHENSIVE = "COMPREHENSIVE";
export const SIGNATURE = "SIGNATURE";

export const PAY_AT_ONCE = "PAY_AT_ONCE";
export const SPREAD_THE_COST = "SPREAD_THE_COST";

export const PRODUCT = process.env.NEXT_PUBLIC_PRODUCT ?? "staysure";
