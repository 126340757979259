import axios from "axios";

import { NOT_FOUND_URL } from "@/constants";
import logError from "@/utils/logger";

const fetchNotFoundContent = async () => {
  let pageJson;
  await axios
    .get(NOT_FOUND_URL)
    .then(async (res) => {
      pageJson = res.data;
    })
    .catch((e) => {
      logError(e, { location: "fetchNotfound.tsx" });
      pageJson = {};
    });

  return pageJson;
};

export default fetchNotFoundContent;
