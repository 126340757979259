"use client";

import React from "react";

import PageNotFound from "@/components/pages/page-not-found/page-not-found";

import "./globals.css";

export const metadata = {
  title: "Staysure™ Travel Insurance - It's Worth Doing Right",
};

function NotFound() {
  return (
    <div data-testid="notfound-page-testid">
      <PageNotFound />
    </div>
  );
}

export default NotFound;
