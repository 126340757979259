/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable check-file/filename-naming-convention */
import React, { ReactNode, useEffect, useRef } from "react";

interface FocusLockProps {
  children: ReactNode;
  disabled: boolean;
}

function FocusLock({ children, disabled }: FocusLockProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const focusedElementBeforeLockRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current as HTMLDivElement;
    const handleKeyDown = (event: KeyboardEvent) => {
      const TAB_KEYCODE = 9;
      if (!container) return;
      const focusableElements = container.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );

      const firstFocusableElement = focusableElements[1] as HTMLElement | null;
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      if (event.keyCode === TAB_KEYCODE) {
        if (event.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            // lastFocusableElement?.focus();
            event.preventDefault();
          }
        } else {
          // Tab
          if (document.activeElement === lastFocusableElement) {
            if (firstFocusableElement) {
              firstFocusableElement.focus();
            }
            event.preventDefault();
          }
        }
      }
    };

    const lock = () => {
      container?.addEventListener("keydown", handleKeyDown);
    };

    const unlock = () => {
      focusedElementBeforeLockRef.current?.focus();

      // Reset the stored focused element
      focusedElementBeforeLockRef.current = null;

      // Remove event listener
      container?.removeEventListener("keydown", handleKeyDown);
    };

    if (!disabled) {
      lock();
    } else {
      unlock();
    }
    return () => unlock();
  }, [disabled]);

  return (
    <div ref={containerRef} tabIndex={disabled ? undefined : -1}>
      {children}
    </div>
  );
}

export default FocusLock;
