import { useEffect, useState } from "react";

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<number | undefined>(undefined);

  useEffect(() => {
    function handleResize() {
      setWindowSize(typeof window !== "undefined" ? window.innerWidth : -1);
    }

    typeof window !== "undefined" &&
      window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
