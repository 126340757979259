import React, { createContext, useContext, useMemo } from "react";

const BrandContext = createContext({
  brandName: "",
});

export const useBrand = () => useContext(BrandContext);

function BrandProvider({ children }: { children: React.ReactNode }) {
  const brandName = process.env.NEXT_PUBLIC_PRODUCT || "staysure";

  const value = useMemo(
    () => ({
      brandName,
    }),
    [brandName]
  );

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
}

export default BrandProvider;
